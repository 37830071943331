import { useTranslation } from 'next-i18next'

import SectionLayout from '../../commons/layouts/SectionLayout'

// configs
import { PRICE_SIZE_TYPE_BAG, PRICE_SIZE_TYPE_SUITCASE } from 'configs/space'

// hooks
import { useSpacePrice } from 'hooks/space/price'

import styles from './CostSection.module.scss'

type Props = {
  countryCode?: string
}

const CostSection: React.FC<Props> = ({ countryCode = 'jpn' }) => {
  const { t } = useTranslation()

  const CostItem = ({ title, cost, description, imageUrl, imageAlt }) => {
    return (
      <div className={styles.item}>
        <div className={styles.itemHeading}>
          <div className={styles.itemPriceBox}>
            <h3 className={styles.itemPriceBoxTitle}>{title}</h3>
            <div className={styles.itemPriceBoxDescription}>
              <div className={styles.itemPrice}>
                {cost} <span>/{t('TOP.COST.PER.DAY')}</span>
              </div>
            </div>
          </div>
          <img src={imageUrl} alt={imageAlt} />
        </div>
        <div className={styles.itemDescription}>{description}</div>
      </div>
    )
  }

  return (
    <SectionLayout sectionName="cost" sectionImageAlt={t('META.COST')} title={t('TOP.COST.TITLE')}>
      <div className={styles.costItems}>
        <div className={styles.costItemsPlan}>
          <CostItem
            title={t('TOP.COST.BAGSIZE')}
            cost={useSpacePrice(countryCode, PRICE_SIZE_TYPE_BAG)}
            description={t('TOP.COST.BAG.DESCRIPTION')}
            imageUrl="/cost_bag_size_group.webp"
            imageAlt={t('META.COST_BAG_SIZE_GROUP')}
          />
          <CostItem
            title={t('TOP.COST.SUITCASESIZE')}
            cost={useSpacePrice(countryCode, PRICE_SIZE_TYPE_SUITCASE)}
            description={t('TOP.COST.SUITCASE.DESCRIPTION')}
            imageUrl="/cost_suitcase_size_group.webp"
            imageAlt={t('META.COST_SUITCASE_SIZE_GROUP')}
          />
        </div>
        <div className={styles.costNote}>
          <div className={styles.costNoteTitle}>{t('TOP.COST.NOTE.TITLE')}</div>
          <div className={styles.costNoteDescription}>{t('TOP.COST.NOTE.DESCRIPTION.ONE')}</div>
          <div className={styles.costNoteDescription}>{t('TOP.COST.NOTE.DESCRIPTION.TWO')}</div>
        </div>
      </div>
    </SectionLayout>
  )
}

export default CostSection
