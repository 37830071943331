import { useTranslation } from 'next-i18next'

import SectionLayout from '../../commons/layouts/SectionLayout'

import checkBreakpoint from 'lib/checkBreakpoint'

import styles from './FeatureSection.module.scss'

interface Props {
  countryCode: string
}

const FeatureSection: React.FC<Props> = ({ countryCode = 'jpn' }: Props) => {
  const { isMobile } = checkBreakpoint()
  const { t } = useTranslation()

  // 特徴 - マップアイコン
  const imageMapIcon = countryCode === 'twn' ? '/feature_map_twn.webp' : '/feature_map.webp'

  const FeatureItem = ({ image, imageAlt, title, description }) => {
    return (
      <div className={styles.item}>
        {isMobile ? (
          <>
            <div className={styles.itemHeading}>
              <img src={image} alt={imageAlt} />
              <div className={styles.itemTitle}>{title}</div>
            </div>
            <p className={styles.itemDesc}>{description}</p>
          </>
        ) : (
          <>
            <div className={styles.itemImage}>
              <img src={image} alt={imageAlt} />
            </div>
            <div className={styles.itemTextBundle}>
              <div className={styles.itemTitle}>{title}</div>
              <p className={styles.itemDesc}>{description}</p>
            </div>
          </>
        )}
      </div>
    )
  }

  return (
    <SectionLayout
      sectionName="feature"
      sectionImageAlt={t('META.FEATURES')}
      title={t('TOP.FEATURES.TITLE')}
    >
      <div className={styles.featureItems}>
        <div className={styles.featureItemsSection}>
          <FeatureItem
            image={imageMapIcon}
            imageAlt={t('META.FEATURE_MAP')}
            title={t('TOP.FEATURES.HEADING.SPACE')}
            description={t('TOP.FEATURES.DESCRIPTION.SPACE')}
          />
          <FeatureItem
            image="/feature_map_pin.webp"
            imageAlt={t('META.FEATURE_MAP_PIN')}
            title={t('TOP.FEATURES.HEADING.LOCATION')}
            description={t('TOP.FEATURES.DESCRIPTION.LOCATION')}
          />
        </div>
        <div className={styles.featureItemsSection}>
          <FeatureItem
            image="/feature_musical_instrument.webp"
            imageAlt={t('META.FEATURE_MUSICAL_INSTRUMENT')}
            title={t('TOP.FEATURES.HEADING.MUSICAL_INSTRUMENT')}
            description={t('TOP.FEATURES.DESCRIPTION.MUSICAL_INSTRUMENT')}
          />
          <FeatureItem
            image="/feature_payment.webp"
            imageAlt={t('META.FEATURE_PAYMENT')}
            title={t('TOP.FEATURES.HEADING.COMPENSATION')}
            description={t('TOP.FEATURES.DESCRIPTION.COMPENSATION')}
          />
        </div>
      </div>
    </SectionLayout>
  )
}

export default FeatureSection
