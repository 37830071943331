import RatingComponent from '../../ratings/RatingComponent'

import styles from './ReviewCard.module.scss'

interface Props {
  review: {
    id: number
    user_name: string
    rating: number
    review_comment: string
  }
}

const ReviewCard: React.FC<Props> = ({ review }: Props): JSX.Element => {
  return (
    <div className={styles.item} key={review.id}>
      <div className={styles.itemInfo}>
        <div className={styles.itemInfoUserName}>{review.user_name}</div>
        <RatingComponent averageRating={review.rating} />
      </div>
      <p className={styles.itemDescription}>{review.review_comment}</p>
    </div>
  )
}

export default ReviewCard
