import Link from 'next/link'
import { useTranslation } from 'next-i18next'

import { Country } from 'state/ducks/country/models'

import SectionLayout from '../../commons/layouts/SectionLayout'

import styles from './CountrySection.module.scss'

interface Props {
  countries: Country[]
  locale: string
}

const CountrySection: React.FC<Props> = ({ countries, locale = 'ja' }) => {
  const { t } = useTranslation()

  /**
   * srcのデータを渡して
   * css background の値を取得する
   * @param string src 画像URL
   * @returns string background style
   */
  const backgroundStyle = (country_code: string) => {
    const src: string = country_code === 'jpn' ? '/country_jp.webp' : '/country_tw.webp'
    return `url("${src}") no-repeat`
  }

  /**
   * 言語によって表示するものを切り替える
   * @param { country } Country 国情報
   * @returns
   */
  const getName = ({ country_code, name, en_name }: Country) => {
    if(country_code === 'jpn')
      return t('TOP.COUNTRY.JP')
    else if(country_code === 'twn')
      return t('TOP.COUNTRY.TW')
    else
      return en_name ?? name
  }

  return (
    <SectionLayout sectionName="country" title={t('TOP.COUNTRY.TITLE')}>
      <>
        {countries.length > 0 && (
          <ul className={styles.countryItems}>
            {countries.map((country, index) => (
              <li
                className={styles.item}
                style={{
                  background: backgroundStyle(country.country_code),
                  backgroundSize: 'cover',
                }}
                key={index}
              >
                <div className={styles.itemOverlay}>
                  <Link href={`/${country.country_code}`} passHref>
                    <a className={styles.areaNameLink}>{getName(country)}</a>
                  </Link>
                </div>
              </li>
            ))}
          </ul>
        )}
      </>
    </SectionLayout>
  )
}

export default CountrySection
