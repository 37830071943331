import React, { useCallback, useMemo } from 'react'
import Carousel from 'react-multi-carousel'
import { osName } from 'lib/checkDevice'

import styles from './MainBannerSection.module.scss'

interface Props {
  locale: string
}

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 992 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 3000, min: 576 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 575, min: 0 },
    items: 1,
  },
}

const MainBannerSection: React.VFC<Props> = ({ locale }: Props) => {
  const getAppDownloadUrl = () => {
    if (osName === 'ios' || osName === 'mac') {
      return 'https://itunes.apple.com/jp/app/ecbo-cloak/id1443707795'
    }
    return 'https://play.google.com/store/apps/details?id=io.ecbo.cloak'
  }

  const appDownloadUrl = getAppDownloadUrl()

  /**
   * @TODO
   * 将来的にAdminから取得するように対応
   */

  const banners = useMemo(() => {
    let appDownloadUrl = ''

    if (osName === 'ios' || osName === 'mac') {
      appDownloadUrl = 'https://itunes.apple.com/jp/app/ecbo-cloak/id1443707795'
    }

    appDownloadUrl = 'https://play.google.com/store/apps/details?id=io.ecbo.cloak'

    return [
      {
        name: {
          en: 'Leave your luggage with us and get crispy and comfortable! Download the app first!',
          ja: '荷物を預けてサクサク快適！まずはアプリをダウンロード',
        },
        image_pc: '/banner_ecbo_app_pc.webp',
        image_sp: '/banner_ecbo_app_sp.webp',
        url: appDownloadUrl,
      },
      {
        name: {
          en: 'HOW TO - Click here to learn how to use ecbo cloak.',
          ja: 'HOW TO - ecbo cloakの使い方はこちら',
        },
        image_pc: '/banner_ecbo_beginner_pc.webp',
        image_sp: '/banner_ecbo_beginner_sp.webp',
        url: 'https://ecbonist.ecbo.io/how_to_use_ecbo_cloak/',
      },
      {
        name: {
          en: 'TOKYO MARINE NICHIDO Tokio Marine Nichido in cooperation with ecbo clock - ecbo clock loss insurance covers up to 200,000 yen (*Some deductibles apply)',
          ja: 'TOKYO MARINE NICHIDO 東京海上日動とecbo clockの提携 - ecbo clockの紛失保険なら最大20万円まで保証(※一部免責金額等があります)',
        },
        image_pc: '/banner_ecbo_insurance_pc.webp',
        image_sp: '/banner_ecbo_insurance_sp.webp',
        url: 'https://intercom.help/ecbocloak/ja/articles/9333901',
      },
    ]
  }, [])

  const CustomDot: React.JSXElementConstructor<any> = useCallback(({ onClick, active }) => {
    return (
      <li
        className={`${styles.mainBannersDotsItem} ${
          active ? styles.mainBannersDotsItemActive : ''
        }`}
        onClick={() => onClick()}
      />
    )
  }, [])

  return (
    <section className={styles.mainBannerSection}>
      <Carousel
        responsive={responsive}
        autoPlay={false}
        infinite
        swipeable
        className={styles.mainBanners}
        arrows={false}
        showDots={true}
        autoPlaySpeed={3000}
        dotListClass={styles.mainBannersDots}
        customDot={<CustomDot />}
      >
        {banners.map((banner, index) => {
          return (
            <div
              onClick={() => window.open(banner.url, '_blank')}
              className={styles.bannerContainer}
              key={index}
            >
              <div
                className={styles.bannerPC}
                style={{ backgroundImage: `url(${banner.image_pc})` }}
              />

              <div
                className={styles.bannerMobile}
                style={{ backgroundImage: `url(${banner.image_sp})` }}
              />
            </div>
          )
        })}
      </Carousel>
    </section>
  )
}

export default MainBannerSection
