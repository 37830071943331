import { VFC } from 'react'

import { useTranslation } from 'next-i18next'

import SectionLayout from '../../commons/layouts/SectionLayout'

import styles from './UsageSection.module.scss'

interface Props {
  style?: string
}

const UsageSection: VFC<Props> = ({ style }: Props) => {
  const { t } = useTranslation()

  const UsageItem = ({ numberImage, numberAlt, itemImage, itemImageAlt, itemText }) => {
    return (
      <div className={styles.item}>
        <div className={styles.itemNumber}>
          <img src={numberImage} alt={numberAlt} />
        </div>
        <img src={itemImage} alt={itemImageAlt} />
        <div className={styles.itemText}>{itemText}</div>
      </div>
    )
  }
  return (
    <SectionLayout
      sectionName="usage"
      sectionImageAlt={t('META.HOW_TO_USE')}
      title={t('TOP.USAGE.TITLE')}
      style={style}
    >
      <div className={styles.usageItems}>
        <UsageItem
          numberImage="/how_to_number_1.webp"
          numberAlt={t('META.HOW_TO_NUMBER_1')}
          itemImage="/how_to_1.webp"
          itemImageAlt={t('META.HOW_TO_1')}
          itemText={t('TOP.USAGE.SCENE.ONE')}
        />
        <UsageItem
          numberImage="/how_to_number_2.webp"
          numberAlt={t('META.HOW_TO_NUMBER_2')}
          itemImage="/how_to_2.webp"
          itemImageAlt={t('META.HOW_TO_2')}
          itemText={t('TOP.USAGE.SCENE.TWO')}
        />
        <UsageItem
          numberImage="/how_to_number_3.webp"
          numberAlt={t('META.HOW_TO_NUMBER_3')}
          itemImage="/how_to_3.webp"
          itemImageAlt={t('META.HOW_TO_3')}
          itemText={t('TOP.USAGE.SCENE.THREE')}
        />
      </div>
    </SectionLayout>
  )
}

export default UsageSection
