import { useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import SearchForm from 'components/domains/spaces/SearchForm'

import styles from './SearchSection.module.scss'

interface Props {
  locale: string
  countryCode?: string
}

const SearchSection: React.FC<Props> = ({ locale, countryCode = 'jpn' }) => {
  const router = useRouter()

  const { t } = useTranslation()

  const _onPressNavigationSearchSpacePage = useCallback(
    (
      value,
      selectedDate,
      startDateTime,
      endDateTime,
      bagSize,
      suitcaseSize,
      lat,
      lon,
      isLocation?
    ): void => {
      router.push({
        pathname: '/locations',
        query: {
          name: value,
          startDate: selectedDate.startDate ? selectedDate.startDate.format('YYYY-MM-DD') : '',
          endDate: selectedDate.endDate ? selectedDate.endDate.format('YYYY-MM-DD') : '',
          startDateTimeHour: startDateTime.substring(0, startDateTime.indexOf(':')),
          startDateTimeMin: startDateTime.substring(startDateTime.indexOf(':') + 1),
          endDateTimeHour: endDateTime.substring(0, endDateTime.indexOf(':')),
          endDateTimeMin: endDateTime.substring(endDateTime.indexOf(':') + 1),
          bagSize: bagSize,
          suitcaseSize: suitcaseSize,
          lat,
          lon,
          isLocation: isLocation,
        },
      })
    },
    [router]
  )

  return (
    <section
      className={`${styles.searchSection} ${
        countryCode === 'twn' ? styles.searchSectionCountryTwn : ''
      }`}
    >
      <div className={styles.searchSectionHeading}>
        <h1 className={styles.searchSectionHeadingTitle}>{t('TOP.TITLE')}</h1>
        <div className={styles.searchSectionHeadingSubTitle}>{t('TOP.SUBTITLE')}</div>
      </div>
      <div className={styles.searchArea}>
        <div className={styles.searchAreaContent}>
          <SearchForm
            page="top"
            onPressNavigationSearchSpacePage={_onPressNavigationSearchSpacePage}
            locale={locale}
          />
        </div>
      </div>
    </section>
  )
}

export default SearchSection
