import { VFC } from 'react'
import { useTranslation } from 'next-i18next'

import AppStoreButtonComponent from '../../buttons/AppStoreButtonComponent'
import GooglePlayButtonComponent from '../../buttons/GooglePlayButtonComponent'

import styles from './StoreSection.module.scss'

interface Props {
  style?: string
}

const StoreSection: VFC<Props> = ({ style }: Props) => {
  const { t } = useTranslation()

  return (
    <section className={`${styles.storeSection} ${style ? style : ''}`}>
      <div className={styles.storeSectionArea}>
        <div className={styles.storeSectionTitle}>{t('TOP.STORE.TITLE')}</div>
        <div className={styles.storeSectionBanner}>
          {/* TODO: Adujstを使用する場合は、そのリンク先で経由する実装をする */}
          <AppStoreButtonComponent />
          <GooglePlayButtonComponent />
        </div>
      </div>
      <div>
        <img
          className={styles.storeSectionImage}
          src="/app_main_visual.webp"
          alt={t('META.APP_MAIN_VISUAL')}
        />
      </div>
    </section>
  )
}

export default StoreSection
